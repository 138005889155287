/* VIDEO CIRCLE */
.special-video__wrap {
  position: fixed;
  left: 37vw;
  top: 64%;
  width: 20rem;
  height: 30rem;
  border-radius: 2rem;
  box-shadow: 0 7px 8px -4px #662d8c, 0 10px 30px 4px rgba(207,0,255,.5), 0 4px 5px -1px #2b0950, 0 0 1px 0 rgba(207,0,255,.5), inset 0 2px 6px 0 #ff005b;
  border: 2px solid #662d8c;
  background: black;
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  transform: translate(0,30px);
  transition: opacity 0.3s ease-in-out;
  @include media-breakpoint-down(md) {
    left: 20px;
    top: 20%; }
  &--active {
    opacity: 1;
    visibility: visible;
    transform: none;
    transition: all 0.5s ease-in-out !important;
    transition-property: all !important;
    transition-delay: 0 !important; }
  &--hidden {
    opacity: 0; }
  &--move {
    transition: none !important; }
  &--full {
   transform: translate3d(-50%, -50%, 0) !important; }
  &--full-size {
    width: 800px; height: 600px;
    .special-video__btn-list {
      opacity: 1;
      pointer-events: auto; } }
  &:hover {
    -webkit-box-shadow: 0 10px 10px -4px #662d8c, 0 15px 30px 4px rgba(207,0,255,.5), 0 7px 7px -1px #2b0950, 0 1px 1px 0 rgba(207,0,255,.5), inset 0 0 8px 0 #ff005b;
    box-shadow: 0 10px 10px -4px #662d8c, 0 15px 30px 4px rgba(207,0,255,.5), 0 7px 7px -1px #2b0950, 0 1px 1px 0 rgba(207,0,255,.5), inset 0 0 8px 0 #ff005b;
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); } }

.special-video__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2rem;
  cursor: pointer;
  &--hidden {
    display: none; } }

.special-video__btn-list {
  display: flex;
  position: absolute;
  bottom: -2rem;
  left: 50%;
  transform: translateY(100%) translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out; }

.special-video__btn {
  margin-left: 1rem;
  margin-right: 1rem;
  transform: none !important; }


@media (max-width: 991.98px) {
  .special-video__wrap {
    width: 150px;
    height: 150px;
    &--full-size {
      width: 80vw;
      height: 35vh; } } }

@media (max-width: 768px) {
  .special-video__wrap {
    height: 200px;
    left: 20px;
    top: 34%;
    &--full-size {
      width: 98vw;
      height: 35vh; } } }



