.awards {
  display: none;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-item-align: start;
  align-self: flex-start;
  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 1.5rem;
  padding-left: 2rem;
  padding-top: 1rem; }

@media screen and (min-width: 993px) {
  .awards {
    display: flex; } }

.cssda-badge {
  z-index: 0;
  box-shadow: 0.8rem 0 1rem rgba(0, 0, 0, 0.5);
  margin: 0 -1.5rem;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  overflow: hidden;
  color: #ededed;
  background: currentColor;
  transition: color 0.25s cubic-bezier(0.615, 0.19, 0.305, 0.91);
  cursor: pointer; }

.awards {
  .cssda-badge svg {
    pointer-events: none;
    position: absolute;
    width: 7rem;
    height: 7rem;
    fill: #000; }

  .cssda-badge-content {
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    width: 7rem;
    height: 7rem;
    transition: transform 0.12s cubic-bezier(0.615, 0.19, 0.305, 0.91); }

  .cssda-badge {
    svg {
      pointer-events: none;
      position: absolute;
      width: 7rem;
      height: 7rem;
      fill: #000; }

    &:focus, &:hover {
      animation: bounce .75s linear both;
      color: #673ab7; }

    &:focus svg, &:hover svg {
      fill: #ededed; }

    &:focus .cssda-badge-content, &:hover .cssda-badge-content {
      transform: rotate(1turn); }

    &:focus .cssda-badge-logo, &:hover .cssda-badge-logo {
      transform: scale(1.1); } } }
