//@import "./normalize"
@import "./vars";
@import "./mixins";
@import "./fonts";
//@import "./sprite_generated"

// From libs:
//@import "../../libs/LIB_NAME/path/to/style.scss"

@import '../../libs/bootstrap/scss/mixins/_breakpoints.scss';
@import "../../libs/bootstrap/scss/bootstrap-reboot.scss";
@import "../../libs/bootstrap/scss/_grid.scss";
@import "../../libs/bootstrap/scss/_utilities.scss";

@import "../../libs/aos/dist/aos";

@import "../../libs/swiper/swiper-bundle";
@import "../../libs/@glidejs/glide/dist/css/glide.core";
@import "../../libs/@glidejs/glide/dist/css/glide.theme";

@import "../../libs/glitch/demo";


* {
    box-sizing: border-box;
 }    // min-height: 0.001vw; // — Fixes Safari bug with viewport units in calc()

html {
  font-size: 0.95vh; }

body {
    display: flex;
    flex-direction: column;
    background-color: #110f26;
    color: $text;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    min-width: 320px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.8rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

::placeholder {
	color: #666; }

::selection {
	background-color: $accent1;
	color: #fff; }



a {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none; } }


#block-projects {
  padding-top: $section-top-padding; }

#block-main {
  background: linear-gradient(100deg, rgb(61, 29, 68) 0%, rgb(25, 17, 32) 79%, rgb(35, 16, 38) 100%);
  position: relative;
  z-index: 5;
  min-height: 100vh;
  padding-top: $section-top-padding;
  > .container {
    margin-left: 0;
    padding-left: 5%;
    max-width: 88%;
    // max-width: 180rem
    &:first-child {
      position: relative; }
    .navigation {
      top: 0; } }
  &::before {
    content: " ";
    background: url(../img/normal-1-min.png);
    background-position: center  !important;
    background-repeat: no-repeat !important;
    background-size: 108rem !important;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1; }
  &::after {
    content: " ";
    background: url(../img/normal-2-min.png);
    background-position: center  !important;
    background-repeat: no-repeat !important;
    background-size: 108rem !important;
    position: absolute;
    animation: Neon 8s ease infinite;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }

@keyframes Neon {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  35% {
    opacity: 0.2; }
  60% {
    opacity: 1; }
  75% {
    opacity: 0.3; }
  85% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.container {
  max-width: 162.5rem;
  &--thin {
    max-width: 134rem; }
  &.is-relative {
    position: relative;
    .navigation {
      right: 16.3rem; } } }


.section {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 1rem;
  .section-title {
    font-size: $section-title-size;
    font-family: 'DrukText';
    letter-spacing: -0.55rem;
    text-transform: uppercase;
    line-height: 1;
    text-shadow: 15px 15px 30px rgba(17, 15, 38, 0.7);
    margin-bottom: 7rem;
    &--foreign-language {
      line-height: 1.1; }
    &.section-title__mb-null {
      margin-bottom: 0; }
    &.is-md {
      font-size: $section-title-size-md; }
    &--page-title {
      font-size: 4.8rem; } }
  .section-description {
    font-weight: 700;
    font-size: $section-description-size;
    line-height: 1.58;
    margin-bottom: 5rem; }
  .section-subtitle {
    font-size: 2.4rem; }
  .section-action {
    margin-bottom: 8rem;
    .btn {
      &:not(:last-child) {
        margin-right: 1.7rem; } }
    &--bottom {
      position: absolute;
      bottom: 0rem;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      > .btn {
        font-size: 1.8rem;
        padding: 2.4rem 3.4rem;
        border-radius: 3.4rem; }
      > .hex {
        margin-bottom: 4rem;
        .btn {
          font-size: 2.5rem; } } } } }

.btn {
  display: inline-block;
  line-height: 1;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 2rem;
  transition: all 0.3s ease-in-out;
  background: #271c36;
  font-weight: bold;
  padding: 1.5rem 2.1rem;
  font-size: 1.6rem;
  &[disabled] {
    pointer-events: none;
    opacity: 0.5; }
  &:hover,
  &:focus {
    color: white;
    background: #342a43; }
  &.btn--lg {
    padding: 1.333333rem 2.125rem;
    border-radius: 3.5rem;
    padding: 2.4rem 3.4rem;
    font-size: 1.8rem; }
  &.btn--sm {
    padding: 1rem 1.6rem;
    font-size: 1.6rem; }
  &.btn--primary {
    background: linear-gradient(to right, $accent1, $accent2);
    box-shadow: 0 7px 8px -4px #662d8c, 0 10px 30px 4px rgba(207, 0, 255, 0.5), 0 4px 5px -1px #2b0950, 0 0 1px 0 rgba(207, 0, 255, 0.5), inset 0px 0px 0px 1px rgba(255,255,255, .24);
    &:hover,
    &:focus {
      box-shadow: 0 10px 10px -4px #662d8c, 0 15px 30px 4px rgba(207, 0, 255, 0.5), 0 7px 7px -1px #2b0950, 0 1px 1px 0 rgba(207, 0, 255, 0.5), inset 0px 0px 0px 1px rgba(255,255,255, .24);
      transform: translateY(-3px); }
    &:active {
      box-shadow: 0 7px 8px -4px #662d8c, 0 5px 30px 4px rgba(207, 0, 255, 0.5), 0 2px 5px -1px #2b0950, 0 1px 1px 0 rgba(207, 0, 255, 0.5);
      transform: translateY(0); } }
  &.btn--secondary {
    background: linear-gradient(to right, $primary1, $primary2);
    box-shadow: 0 7px 8px -4px #662d8c, 0 10px 30px 4px rgba(207, 0, 255, 0.5), 0 4px 5px -1px #2b0950, 0 0 1px 0 rgba(207, 0, 255, 0.5), inset 0 2px 6px 0 #ff005b;
    &:hover,
    &:focus {
      box-shadow: 0 10px 10px -4px #662d8c, 0 15px 30px 4px rgba(207, 0, 255, 0.5), 0 7px 7px -1px #2b0950, 0 1px 1px 0 rgba(207, 0, 255, 0.5), inset 0 0 8px 0 #ff005b;
      transform: translateY(-3px); }
    &:active {
      box-shadow: 0 7px 8px -4px #662d8c, 0 5px 30px 4px rgba(207, 0, 255, 0.5), 0 2px 5px -1px #2b0950, 0 1px 1px 0 rgba(207, 0, 255, 0.5), inset 0 3px 12px 0 #ff005b;
      transform: translateY(0); } }
  &--image {
    padding: 0;
    border: none;
    box-shadow: none;
    box-shadow: 0 7px 8px -4px #662d8c, 0 10px 30px 4px rgba(207, 0, 255, 0.5), 0 4px 5px -1px #2b0950, 0 0 1px 0 rgba(207, 0, 255, 0.5), inset 0 2px 6px 0 #ff005b;
    &:hover,
    &:focus {
      box-shadow: 0 10px 10px -4px #662d8c, 0 15px 30px 4px rgba(207, 0, 255, 0.5), 0 7px 7px -1px #2b0950, 0 1px 1px 0 rgba(207, 0, 255, 0.5);
      transform: translateY(-3px); }
    &:active {
      transform: translateY(0); } }
  &--icon {
    border-radius: 1.8rem;
    display: block;
    height: 7rem !important;
    padding: 0;
    height: auto; }
  &__icon {
    position: relative;
    width: 1.4em;
    height: 1.9rem;
    // top: 0.15rem
    // line-height: 0
    &--left {
      margin-left: -1.1rem;
      margin-right: 1.7rem; } }
  .btn-icon-left {}
  &--terms {
    &::before, &::after {
      content: none !important; }

    position: relative;
    z-index: 10;
    margin-top: 0px;
    color: white;
    margin-top: 5px;
    font-size: 80%;
    color: white !important;
    transition: opacity .3s ease-in-out;
    text-decoration: none !important;

    &:hover, &:focus {
      opacity: 0.6; } } }

.text-center {
	text-align: center; }
.text-right {
	text-align: right !important; }

b, strong {
	font-weight: bold; }

.box {
  background-image: linear-gradient(100deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0));
  border: solid 1px rgba(255, 255, 255, 0.12);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3rem;
  width: auto;
  padding: 1.2rem 3rem 1.2rem 0.5rem;
  &__image {
    height: 9.2rem;
    width: auto;
    border-radius: 1rem; }
  .box-caption {
    padding-top: 0.888889rem;
    padding-bottom: 0.888889rem;
    padding: 0;
    font-weight: 500;
    margin-left: 1.6rem;
    line-height: 1.2;
    &--bold {
      font-weight: 600; }
    &--lg {
      margin-left: 3.2rem;
      margin-right: 2.4rem;
      line-height: 1.56; }
    p {
      margin: 0 0 1rem 0;
      &:last-child {
        margin-bottom: 0; } }
    a {
      color: $accent1; } }
  &--row {
    padding-left: 1.388889rem;
    flex-direction: row; } }

.box_first_screen {
  padding-top: 0.2rem;
  padding-bottom: 0.4rem;
  padding-left: 0.5rem;
  margin-bottom: 7.2rem;
  border-top-left-radius: 1.8rem;
  border-bottom-left-radius: 1.8rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: 4.2rem;
  border-bottom-right-radius: 4.2rem;
  padding-right: 2.5rem;
  margin-bottom: 7.2rem; }

.text--accent {
  color: $accent1; }


.link {
  &--bold {
    font-weight: 600; }
  &__icon-arrow-right {
    margin-left: 0.4rem;
    position: relative;
    transition: all 0.3s linear;
    left: 0;
    top: 0.2rem;
    height: 0.8em;
    width: 0.8em; }
  &:hover,
  &:focus {
    .link__icon-arrow-right {
      margin-left: 0.7rem; } } }


.icon-arrow-right {
  margin-left: 0.4rem;
  position: relative;
  transition: all 0.3s linear;
  left: 0;
  top: 0.2rem;
  height: 0.8em;
  width: 0.8em; }

a {
  &.icon-arrow-right {
    position: relative;

    &::after {
      display: inline-block;
      position: absolute;
      top: 0.07rem;
      position: absolute;
      content: "\f105";
      font-family: 'Font Awesome 5 Free';
      font-style: normal;
      font-weight: 900;
      font-size: 1.2rem;
      line-height: 0;
      top: 50%;
      transform: translateY(-50%);
      right: -0.80rem;
      transition: all 0.3s linear; }
    &:hover,
    &:focus {
      &::after {
        right: -0.95rem; } } } }

#man-1 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 44.6rem;
  height: 100rem;
  display: flex;
  max-height: none !important;
  max-width: none !important;
  &.is-active {
    .glitch__img {
      &:first-child {
        // background: url(/img/mans/man-1-2-min.png)
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important; } } }
  .glitch__img {
    background: url(../img/mans/man1-min.png);
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    max-height: none !important;
    max-width: none !important;
    &:nth-of-type(2) {
      background: url(../img/mans/man1-2-min.png); }
    &:nth-of-type(3) {
      background: url(../img/mans/man1-3-min.png); } } }


#man-2 {
  position: absolute;
  right: 8rem;
  bottom: 0;
  width: 95.4rem;
  height: 93rem;
  display: flex;
  max-height: none !important;
  max-width: none !important;
  &.is-active {
    .glitch__img {
      &:first-child {
        // background: url(/img/mans/man-1-2-min.png)
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important; } } }
  .glitch__img {
    background: url(../img/mans/man2-min.png);
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    max-height: none !important;
    max-width: none !important;
    &:nth-of-type(2) {
      background: url(../img/mans/man2-3-min.png); }
    &:nth-of-type(3) {
      background: url(../img/mans/man2-2-min.png); } } }

.hex {
  position: relative;
  margin: 0 1.5rem;
  flex-shrink: 0;
  padding: 3.2rem;
  background: url(../img/shape.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  &__icon {
    width: 2.4rem; }
  .btn {
    border-radius: 50%;
    width: 7rem;
    height: 7rem;
    z-index: 10;
    padding: 1.5rem;
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center; } }

.humans-list {
  display: flex;
  justify-content: space-between;
  .human-info {
    margin-right: 3.6rem;
    &:last-of-type {
      margin-right: 0; } } }

.human-info {
  text-shadow: 2px 2px 5px #110f26, 2px 2px 5px rgba(17, 15, 38, 0.6);
  &__title {
    font-size: 1.333333rem;
    text-transform: uppercase;
    margin-bottom: 0.444444rem;
    line-height: 1.1;
    font-size: 2.4rem;
    font-family: 'DrukText'; }
  &__subtitle {
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 1.8rem; } }



.links-list {
  display: flex;
  a {
    position: relative;
    color: white;
    transition: all 0.3s ease-in-out;
    text-decoration: none !important;
    &:not(:last-of-type) {
      margin-right: 1.5rem; }
    &::before {
      content: none !important; }
    &:hover {
      opacity: 0.4; } }
  img {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
    object-position: center; } }

.burger {
  width: 2.777778rem;
  height: 2.777778rem;
  top: 3.388889rem;
  position: absolute;
  right: 4rem;
  border-radius: 4px;
  z-index: 10;
  background: none;
  border: none;
  span {
    position: relative;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    user-select: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -1.5px;
    display: block;
    width: 30px;
    height: 1px;
    background-color: white;
    outline: 1px solid transparent;
    transition-property: background-color, transform;
    transition-duration: 0.3s;
    &::before, &::after {
      display: block;
      width: 30px;
      height: 2px;
      background-color: white;
      outline: 1px solid transparent;
      transition-property: background-color, transform;
      transition-duration: 0.3s; }
    &::before, &::after {
      position: absolute;
      content: "";
      will-change: transform; }
    &::before {
      top: -9px; }
    &::after {
      top: 9px; } }
  &.clicked span {
    background-color: transparent;
    &::before {
      transform: translateY(9px) rotate(45deg); }
    &::after {
      transform: translateY(-9px) rotate(-45deg); }
    &:before, &:after {
      background-color: #ffffff; } }
  &:hover {
    cursor: pointer; } }

// nav.burger-menu
//   background-color: rgba($gray-700, 0.50)
//   position: fixed
//   z-index: 9
//   top: 0
//   right: 0
//   height: 100%
//   max-width: 40rem
//   width: 100%
//   padding: 15rem 4rem 0rem 4rem
//   overflow-y: auto
//   transform: translateX(100%)
//   transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86)
//   &.show
//     transform: translateX(0px)
//     ul.main li
//       transform: translateX(0px)
//       opacity: 1
//       &:nth-child(1)
//         transition-delay: 0.15s
//       &:nth-child(2)
//         transition-delay: 0.3s
//       &:nth-child(3)
//         transition-delay: 0.45s
//       &:nth-child(4)
//         transition-delay: 0.6s
//       &:nth-child(5)
//         transition-delay: 0.75s
//       &:nth-child(6)
//         transition-delay: 0.9s
//       &:nth-child(7)
//         transition-delay: 1.05s
//       &:nth-child(8)
//         transition-delay: 1.2s
//       &:nth-child(9)
//         transition-delay: 1.35s
//   ul.main
//     list-style-type: none
//     li
//       margin-bottom: 20px
//       transform: translateX(40px)
//       opacity: 0
//       transition: all 0.3s ease
//       &:last-of-type
//         margin-bottom: 0px
//       a
//         color: #ffffff
//         font-family: "Raleway", sans-serif
//         text-decoration: none
//         text-transform: uppercase
//         font-size: 1.5rem
//         display: inline-block
//         letter-spacing: 5px
//         font-weight: 600
//         padding: 10px 10px
//         transition: all 0.3s ease
//         &:hover,
//         &:focus
//           background: $accent1

.blocker {
  z-index: 5;
  padding: 0; }


#awesome-projects {
  width: 100%;
  max-width: none;
  border-radius: 0;
  height: 100%;
  z-index: 6;
  background: #000;
  .close-modal {
    display: none; }
  .back-modal {
    position: absolute; } }

.navigation {
  font-size: 1.8rem;
  position: absolute;
  right: 10%;
  top: $section-top-padding;
  z-index: 12;
  width: auto;
  .navigation-menu {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    a {
      color: white;
      &::before {
        content: none; } }
    > li {
      margin-right: 4.8rem;
      &:last-child {
        margin-right: 0; }
      > a {
        transition: opacity 0.3s ease-in-out;
        &:hover,
        &:focus {
          opacity: 0.6;
          text-decoration: none; } } } } }
a {
  &.no-underline {
    &::before {
      content: none !important; } }
  &:not(.btn), {
    position: relative;
    &:before {
      position: absolute;
      bottom: -.1rem;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background-color: #CF2034;
      content: "";
      transform: scale(0);
      transition: transform .4s cubic-bezier(0.11, 0.7, 0, 1); }
    &:hover,
    &:focus {
      text-decoration: none !important;
      &:before {
        transform: scale(1); } } } }


.dropdown {
  position: relative;
  .dropdown-title {
    border-radius: 2rem;
    padding: 1rem 4.8rem 1rem 1.8rem;
    background: #271c36;
    position: relative;
    transition: all 0.3s ease-in-out;
    z-index: 2;
    position: relative;
    display: block;
    width: 100%;
    &::after {
      position: absolute;
      content: "";
      right: 1.5rem;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      width: 2.0rem;
      height: 1.3rem;
      background: url(../img/icons/arr-right.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transition: all 0.2s ease-in-out; }
    &:hover {
      background: #342a43;
      cursor: pointer; } }
  .dropdown-list {
    margin-top: -1.5rem;
    border-radius: 0 0  2rem 2rem;
    opacity: 0;
    // z-index: -1
    display: none;
    position: absolute;
    list-style-type: none;
    padding-left: 0;
    background: #271c36;
    left: 0;
    right: 0;
    padding-top: 1.5rem;
    padding-bottom: 2rem;
    li {
      &:first-child {
        margin-top: 1.2rem; }
      a {
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        padding-left: 1.8rem;
        padding-left: 1.8rem;
        display: block;
        transition: all 0.3s ease-in-out;
        &:hover,
        &:focus {
          text-decoration: none;
          color: white;
          background: #332841; } } } }
  &.dropdown--open {
    .dropdown-list {
      opacity: 1;
      z-index: 1;
      display: block; }
    .dropdown-title {
      // background: #342a43
      &::after {
        transform: translateY(-50%) rotate(270deg); } } } }


.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000; }


.js-project-list {
  margin-top: 9.4rem;
  img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    border-radius: 1rem; }
  .glide__slide-description {
    margin-top: 4.8rem;
    opacity: 0;
    font-size: 1.8rem;
    transition: opacity 0.2s ease-in-out;
    line-height: 1.56;
    font-weight: 500;
    .title {
      font-family: "Montserrat";
      font-size: 3.6rem;
      line-height: 1.2;
      font-weight: 600; } }
  .glide__slide {
    opacity: 0.3;
    transition: opacity 0.2s ease-in-out;
    &.glide__slide--active {
      opacity: 1;
      .glide__slide-description {
        opacity: 1; } } }
  .glide__custom-controls {
    position: absolute;
    top: calc(40vh + 15rem);
    width: calc(100% - 0rem);
    left: 0;
    right: 0;
    margin: 0 auto;
    display: none;
    button {
      background: #271c36;
      border: none;
      border-radius: 50%;
      width: 4rem;
      height: 4rem;

      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      z-index: 50;
      cursor: pointer;
      svg {
        width: 1rem; } } }

  .glide__custom-controls-left {
    position: absolute;
    left: 0; }

  .glide__custom-controls-right {
    position: absolute;
    right: 0; } }


.glide__track {
  cursor: default; }
.glide__slide {
  cursor: url(../img/cursor/left.svg), auto;
  &.glide__slide--active {
    cursor: default;
    ~ .glide__slide {
      cursor: url(../img/cursor/right.svg), auto; } } }


.navigation-toogle,
.burger {
  display: none;
  cursor: pointer;
  height: 51px;
  padding: 24px 14px;
  width: 58px;
  position: absolute;
  right: 0;
  z-index: 100;
  span {
    background-color: white;
    display: block;
    height: 2px;
    position: relative;
    transition: top 0.2s ease 0.15s, bottom 0.2s ease 0.15s, background-color 0.05s ease 0.15s, transform 0.25s ease 0s;
    width: 30px;
    z-index: 0;
    &:before, &:after {
      background-color: white;
      display: block;
      height: 2px;
      position: relative;
      transition: top 0.2s ease 0.15s, bottom 0.2s ease 0.15s, background-color 0.05s ease 0.15s, transform 0.25s ease 0s;
      width: 30px;
      z-index: 0; }
    &:before, &:after {
      content: "";
      transform: rotate(0);
      transform-origin: center center;
      z-index: 3; }
    &:before {
      top: -10px; }
    &:after {
      bottom: -7px; } }
  &.active span {
    background-color: transparent;
    transition: background-color 0.05s ease 0.15s;
    &:before, &:after {
      transition: top 0.2s ease 0s, bottom 0.2s ease 0s, transform 0.25s ease 0.15s; }
    &:before {
      top: 1px;
      transform: rotate(-45deg); }
    &:after {
      bottom: 2px;
      transform: rotate(45deg); } } }


.burger {
  display: block; }

.toggle-top-screen {
  display: none;
  img {
    width: 3.5rem;
    height: 3.5rem; } }

.top-screen {
  box-shadow: 0 28px 32px -4px rgba(21, 11, 29, 0.4), 0 60px 180px 0 rgba(21, 11, 29, 0.4), 0 8px 20px -1px rgba(21, 11, 29, 0.4), 0 2px 4px 0 rgba(21, 11, 29, 0.24);
  background-image: linear-gradient(97deg, #38234f, #150b1d);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding-right: 7rem;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: none;
  &.show {
    display: flex; }
  p {
    font-size: 2rem;
    margin: 0; }
  .btn {
    font-size: 1.5rem;
    margin-left: 1rem; } }

#block-main-mobile {
  height: 100vh;
  display: none;
  // background: url(../img/neon-mobile.png)
  // background-position: left 20rem
  // background-repeat: no-repeat !important
  // background-size: 45rem
  &::before {
    position: absolute;
    top: 0;
    height: 100%;
    width: 90rem;
    left: -40rem;
    display: block;
    content: " ";
    background: url(../img/normal-1-min.png);
    background-repeat: no-repeat;
    background-position: center 12rem;
    background-size: contain; }

  .front-items {
    width: 100%;
    .man-1 {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 75vh;
      width: auto;
      max-width: 45vw;
      object-fit: contain;
      object-position: center bottom; }
    .man-2 {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 75vh;
      width: auto;
      max-width: 85vw;
      object-fit: contain;
      object-position: center bottom; } } }

#block-first-man {
  .section-image {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 75vh;
    width: auto;
    // max-width: 90vw
    max-width: 95vw;
    object-fit: contain;
    object-position: center bottom; } }


#block-second-man {
  .section-image {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 75vh;
    width: auto;
    max-width: 90vw;
    object-fit: contain;
    object-position: center bottom; } }


.c-modal {
  display: none;
  position: fixed;
  z-index: 100;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  .c-modal-content {
    position: relative;
    background-color: transparent;
    margin: auto;
    padding: 0;
    width: 100rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    animation-name: animatetop;
    animation-duration: 0.4s; }
  .c-modal-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; } }

  .close {
    color: white;
    font-weight: bold;
    position: absolute;
    right: 0.5rem;
    font-size: 2rem;
    line-height: 1;
    &:hover, &:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer; } }
  .c-modal-header {
    background-color: rgba(darken(#662d8c, 20), 0.8);
    padding: 0.5rem 1rem 0.3rem;
    color: white;
    .c-modal-title {
      font-size: 2rem;
      margin-bottom: 0; } }
  .c-modal-body {
 }    // padding: 2px 16px
  .c-modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white; } }

.c-contact-list {
  position: fixed;
  left: 0;
  right: 0;
  bottom: -100%;
  transition: all 0.4s ease-in-out;
  background-image: linear-gradient(127deg, #38234f, #150b1d);
  width: 100%;
  z-index: 100;
  padding-bottom: 1.6rem;
  padding-top: 1.6rem;
  &--active {
    bottom: 0; }
  &__close {
    margin: 0 auto;
    display: block;
    font-size: 1.6rem;
    padding: 1.5rem 2.1rem;
    line-height: 1; }
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  &__icon {
    width: 2rem;
    height: 2rem;
    display: inline-block; }
  &__link {
    margin: 4.8rem 1.5rem 0;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border: 2px solid rgba(white, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover,
    &:focus {
      border-color: white; } } }



@keyframes animatetopbox {
  from {
    top: -20rem;
    opacity: 0; }
  to {
    top: 0;
    opacity: 1; } }

@keyframes animatetop {
  from {
    top: -30rem;
    opacity: 0; }

  to {
    top: 0;
    opacity: 1; } }

.mobile-layout {
  background: linear-gradient(100deg, rgb(61, 29, 68) 0%, rgb(25, 17, 32) 79%, rgb(35, 16, 38) 100%);
  display: none; }

.swiper-wrapper {
  will-change: transform; }

.section {
  .section-image {
    transition: all 0.60s linear 0.1s;
    transform: translateX(0);
 } }    // will-change: right

.swiper-slide-active {
  .section {
    .section-image {
      transition-delay: 0s;
      transition-duration: 0.3s; } } }

// .swiper-slide-next
//   #block-first-man
//     .section-image
//       left: -20vw

.swiper-slide-next {
  #block-first-man {
    .section-image {
      right: 100%;
      transform: translateX(76%); } } }

.swiper-slide-next {
  #block-second-man {
    .section-image {
      right: 100%;
      transform: translateX(76%); } } }

.swiper-slide-next {
  .section {
    .section-image {
      // right: 100%  !important
 } } }      // transform: translateX(75%)

.swiper-slide-prev {
  .section {
    .section-image {
      // transform: translateX(25%)
      z-index: -1; } } }



// FullHD
@media all and (max-width: 1920px) {
  html {
 } }    // font-size: 8px

//  WSXGA & WXGA++
@media all and (max-width: 1680px) {
  // html
 }  //   font-size: 16px


// SXGA+ & WXGA+
@media all and (max-width: 1440px) {
  html {
    font-size: 7px; }
  #block-main {
    > .container {
      margin-left: auto;
      padding-left: 0; } }
  .container {
    max-width: 1140px; } }

// For notebooks
@media all and (max-width: 1366px) {
  html {
    font-size: 6px; } }

//  SXGA &  WXGA
@media all and (max-width: 1280px) {
  html {
    font-size: 6px; } }

// ≥1200px
@include media-breakpoint-down(lg);

// ≥992px
@include media-breakpoint-down(md) {
  html {
    font-size: 8px; }
  .navigation {
    position: fixed; }
  .mobile-layout {
    display: block;
    // align-items: stretch;
    // width: 100%
    // overflow-x: auto
    .section {
      height: 100vh;
      // width: 100%
 } }      // flex-shrink: 0
  .section {
    padding: 0;
    .section-title {
      padding-top: 8rem;
      font-size: 10rem;
      margin-bottom: 1rem; } }
  #block-projects {
    > .row {
      padding: 0 15px; }
    .btn--sm {
      font-size: 1rem;
      padding: 0.8rem 1.6rem; } }
  #block-main {
    display: none; }
  #block-main-mobile {
    display: block; }
  .js-project-list {
    img {
      height: 40vh; }
    .glide__slide {
      .title {
        padding-left: 4.5rem;
        padding-right: 4.5rem; } }
    .glide__custom-controls {
      display: block; } }

  .toggle-top-screen {
    display: inline-block; }

  .navigation {
    background: transparent;
    top: 0;
    right: 0 !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > [class*="col"] {
      position: static; }
    .navigation-toogle {
      display: inline-block;
      position: static; }
    .navigation-menu {
      text-align: left;
      display: none;
      position: absolute;
      width: 100%;
      box-shadow: 0 28px 32px -4px rgba(21, 11, 29, 0.4), 0 60px 180px 0 rgba(21, 11, 29, 0.4), 0 8px 20px -1px rgba(21, 11, 29, 0.4), 0 2px 4px 0 rgba(21, 11, 29, 0.24);
      background-image: linear-gradient(130deg, #38234f, #150b1d);
      padding: 0;
      margin: 0;
      top: 6.2rem;
      z-index: 100;
      font-size: 1.4rem;
      left: 0;
      > li {
        margin: 0;
        &.has-dropdown {
          border-top: 1px solid rgba(255, 255, 255, 0.12);
          display: block;
          width: 100%;
          padding: 1.5rem 1.6rem;
          .dropdown-title {
            display: block;
            width: 100%; }
          .dropdown-list {
            margin-top: -1.5rem; } }
        > a {
          padding: 1.5rem 1.6rem;
          display: block;
          border-top: 1px solid rgba(255, 255, 255, 0.12);
          margin: 0;
          text-decoration: none !important; } } }
    .navigation-toogle {
      &.active-menu {
        ~ .navigation-menu {
          display: block; } } } } }

// ≥768px
@include media-breakpoint-down(sm) {
  .top-screen {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 1.2rem;
    .btn {
      margin-top: 0.8rem; } }
  .section {
    .section-title {
      &--page-title {
        font-size: 8rem; } } } }


// ≥576px
@include media-breakpoint-down(xs) {
  .section {
    padding-bottom: 0;
    padding-top: 0;
    .section-title {
      font-size: 6.0rem;
      &.is-md {
        font-size: 4rem; }
      &--page-title {
        font-size: 4.5rem; } }
    .section-subtitle {
      font-size: 2.4rem; }
    .section-action {
      &.section-action--bottom {
        flex-direction: column; } } }

  .section {
    &:not(#block-main-mobile) {
      .section-action {
        &.section-action--bottom {
          > .btn {
            font-size: 1.6rem;
            padding: 1.5rem 2.1rem;
            border-radius: 2.1rem;
            z-index: 10; }
          .hex {
            .btn {
              padding: 1.5rem;
              width: 5rem;
              height: 5rem; } } } } } }


  #block-main-mobile {
    .section-action {
      flex-direction: column-reverse !important; }
    .front-items {
      .man-1 {
        right: 0;
        bottom: 0;
        height: 75vh;
        width: auto;
        max-width: 52vw;
        object-fit: contain;
        object-position: center bottom; }
      .man-2 {
        bottom: 0;
        left: 0;
        height: 75vh;
        width: auto;
        max-width: 100vw;
        object-fit: contain;
        object-position: center bottom; } } }

  #block-first-man {
    .section-image {
      height: 65vh;
      width: auto;
      max-width: 145%;
      left: 0;
      z-index: -1; } }

  .swiper-slide-next {
    #block-first-man {
      .section-image {
        left: -30vw;
        transform: translateX(0);
        right: auto; } } }

  .swiper-slide-next {
    #block-second-man {
      .section-image {
        left: -30vw;
        transform: translateX(0);
        right: auto; } } }

  #block-second-man {
    .section-image {
      height: 70vh;
      width: auto;
      max-width: 100vw;
      max-width: 145%;
      left: 0; } }

  .top-screen {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 1.2rem;
    padding-right: 0;
    p {
      font-size: 1.5rem; }
    .btn {
      margin-top: 0.8rem;
      margin-left: 0;
      width: 90%;
      margin-left: auto;
      margin-right: auto !important; } }

  .section {
    .section-action {
      flex-direction: column;
      margin-bottom: 16rem; } } }

@media all and (max-width: 340px) {
  .top-screen {
    img {
      max-width: 13rem; } } }

@media all and (max-height: 450px) {
  #block-main-mobile {
    background-position: center -10rem !important;
    background-size: 100rem !important;
    background: url(../img/normal-1-min.png);
    &::before {
      content: none !important; } }

  .section {
    &:not(#block-main-mobile) {
      .section-action {
        &.section-action--bottom {
          > .btn {
            font-size: 1.6rem;
            padding: 1.5rem 2.1rem;
            border-radius: 2.1rem; } } } } }

  #block-first-man {
    .section-image {
      height: 80vh;
      width: auto;
      max-width: 100vw; } }


  #block-second-man {
    .section-image {
      height: 80vh;
      width: auto;
      max-width: 100vw; } }

  .section {
    .section-action {
      &.section-action--bottom {
        flex-direction: row !important;
        width: 58%;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        .hex {
          margin-bottom: 0;
          .btn {
            padding: 1.5rem;
            width: 5rem;
            height: 5rem; } } } } }

  #block-main-mobile {
    .section-action {
      &.section-action--bottom {
        flex-direction: column-reverse !important;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left !important; } } }

  .section {
    padding-bottom: 0;
    padding-top: 0;
    .section-title {
      font-size: 6.4rem;
      &.is-md {
        font-size: 4rem; }
      &--page-title {
        font-size: 4.5rem; } }
    .section-subtitle {
      font-size: 2.4rem; } } }

@media all and (max-width: 1366px) and (min-height: 760px) and (min-width: 990px) {
  #block-main {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; } }

@import "../blocks/_awards.sass";
@import "../blocks/_special-video.sass";
